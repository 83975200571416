<template>
    <div>
        <div v-if="getFirstOrderLine && getFirstOrderLine.invoiceOnBehalfOf" class="qa-billing-order-invoice-on-behalf-of-name">
            <h5 class="qa-billing-order-invoice-on-behalf-of-name-label">{{ PROVIDER.Order.OrderDetails.InvoiceOnBehalfOf.Name }}</h5>
            <p class="qa-billing-order-invoice-on-behalf-of-name-value">
                {{ getFirstOrderLine.invoiceOnBehalfOf.name || '-' }}
            </p>
            <h5 class="qa-billing-order-invoice-on-behalf-of-vat-number-label">{{ PROVIDER.Order.OrderDetails.InvoiceOnBehalfOf.VatNumber }}</h5>
            <p class="qa-billing-order-invoice-on-behalf-of-vat-number-value">
                {{ getFirstOrderLine.invoiceOnBehalfOf.vatNumber || '-' }}
            </p>
        </div>
       
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

const EditInvoiceOnBehalfOfPopupComponent = () =>
    import('@/components/molecules/EditInvoiceOnBehalfOfPopupComponent')

export default {
    name: 'InvoiceOnBehalfOfComponent',
    components: {
        EditInvoiceOnBehalfOfPopupComponent
    },
    data() {
        return {
            PROVIDER
        }
    },
    computed: {
        ...mapState('orderModule', ['order']),
        ...mapGetters('orderModule', ['getFirstOrderLine'])
    },
}
</script>